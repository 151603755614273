var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v2"},[_c('b-row',{staticClass:"auth-inner m-0"},[_c('b-col',{staticClass:"d-none d-lg-flex align-items-center p-5",attrs:{"lg":"8"}},[_c('div',{staticClass:"w-100 d-lg-flex align-items-center justify-content-center px-5"},[_c('b-img',{attrs:{"fluid":"","src":_vm.imgUrl,"alt":"Login V2"}})],1)]),_c('b-col',{staticClass:"d-flex align-items-center auth-bg px-2 p-lg-5",attrs:{"lg":"4"}},[_c('b-col',{staticClass:"px-xl-2 mx-auto",attrs:{"sm":"8","md":"6","lg":"12"}},[_c('b-card-title',{staticClass:"mb-1 font-weight-bold",attrs:{"title-tag":"h2"}},[_vm._v(" Welcome to Reporting & Management ")]),_c('b-card-text',{staticClass:"mb-2"},[_vm._v(" "+_vm._s(_vm.tagline)+" ")]),(_vm.stage === 'login')?_c('validation-observer',{ref:"loginForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"auth-login-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.login.apply(null, arguments)}}},[_c('b-form-group',{attrs:{"label":"Email","label-for":"login-email"}},[_c('validation-provider',{attrs:{"name":"Email","vid":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"login-email","state":errors.length > 0 ? false : null,"name":"login-email","placeholder":"john@example.com","tabindex":"1"},model:{value:(_vm.userEmail),callback:function ($$v) {_vm.userEmail=$$v},expression:"userEmail"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',[_c('div',{staticClass:"d-flex justify-content-between"},[_c('label',{attrs:{"for":"login-password"}},[_vm._v("Password")]),_c('b-link',{attrs:{"to":{ name: 'auth-forgot-password' }}},[_c('small',[_vm._v("Forgot Password?")])])],1),_c('validation-provider',{attrs:{"name":"Password","vid":"password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"login-password","state":errors.length > 0 ? false : null,"type":_vm.passwordFieldType,"name":"login-password","placeholder":"Password","tabindex":"2"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',[_c('b-form-checkbox',{attrs:{"id":"remember-me","name":"checkbox-1"},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}},[_vm._v(" Remember Me ")])],1),_c('b-button',{attrs:{"type":"submit","variant":"primary","block":"","disabled":invalid,"tabindex":"3"}},[_vm._v(" Sign in ")])],1)]}}],null,false,410454532)}):_vm._e(),(_vm.stage === 'request_mfa')?_c('validation-observer',{ref:"mfaForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"auth-login-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.login(false)}}},_vm._l((_vm.mfaMethods),function(mfa){return _c('b-card',{key:mfa.id,attrs:{"no-body":""}},[_c('b-card-body',{staticClass:"d-flex align-items-center"},[_c('b-avatar',{attrs:{"variant":"light-primary","size":"45"}},[_c('font-awesome-icon',{attrs:{"size":"2x","icon":_vm.iconOfMfaType(mfa.type)}})],1),_c('div',{staticClass:"truncate ml-1"},[_c('h4',{staticClass:"mb-25 font-weight-bolder"},[_vm._v(" "+_vm._s(mfa.name || _vm.title(mfa.type))+" ")]),_c('span',[_vm._v(_vm._s(mfa.display))])]),_c('a',{staticClass:"ml-auto",attrs:{"href":"javascript:"},on:{"click":function($event){return _vm.requestMfa(mfa)}}},[_c('b-avatar',{attrs:{"variant":"light-primary","size":"35"}},[_c('font-awesome-icon',{attrs:{"size":"1x","icon":"fa-solid fa-arrow-right-from-bracket"}})],1)],1)],1)],1)}),1)]}}],null,false,541620909)}):_vm._e(),(_vm.stage === 'submit_mfa')?_c('validation-observer',{ref:"mfaForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"auth-login-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.login.apply(null, arguments)}}},[_c('b-form-group',{attrs:{"label":"Verification Code","label-for":"verification-code"}},[_c('validation-provider',{attrs:{"name":"Verification Code","vid":"code","rules":"required|string"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"verification-code","state":errors.length > 0 ? false : null,"name":"verification-code","placeholder":"975871","tabindex":"1"},model:{value:(_vm.mfaCode),callback:function ($$v) {_vm.mfaCode=$$v},expression:"mfaCode"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-button',{attrs:{"type":"submit","variant":"primary","block":"","disabled":!_vm.mfaCode,"tabindex":"3"}},[_vm._v(" Sign in ")])],1)]}}],null,false,1217879008)}):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }