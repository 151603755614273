<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Left Text-->
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img fluid :src="imgUrl" alt="Login V2" />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title class="mb-1 font-weight-bold" title-tag="h2"> Welcome to Reporting & Management </b-card-title>
          <b-card-text class="mb-2">
            {{ tagline }}
          </b-card-text>

          <!-- form -->
          <validation-observer v-if="stage === 'login'" ref="loginForm" #default="{ invalid }">
            <b-form class="auth-login-form mt-2" @submit.prevent="login">
              <!-- email -->
              <b-form-group label="Email" label-for="login-email">
                <validation-provider #default="{ errors }" name="Email" vid="email" rules="required|email">
                  <b-form-input
                    id="login-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false : null"
                    name="login-email"
                    placeholder="john@example.com"
                    tabindex="1"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Password</label>
                  <b-link :to="{ name: 'auth-forgot-password' }">
                    <small>Forgot Password?</small>
                  </b-link>
                </div>
                <validation-provider #default="{ errors }" name="Password" vid="password" rules="required">
                  <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid' : null">
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="Password"
                      tabindex="2"
                    />
                    <b-input-group-append is-text>
                      <feather-icon class="cursor-pointer" :icon="passwordToggleIcon" @click="togglePasswordVisibility" />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- checkbox -->
              <b-form-group>
                <b-form-checkbox id="remember-me" v-model="status" name="checkbox-1"> Remember Me </b-form-checkbox>
              </b-form-group>

              <!-- submit buttons -->
              <b-button type="submit" variant="primary" block :disabled="invalid" tabindex="3"> Sign in </b-button>
            </b-form>
          </validation-observer>

          <!-- form -->
          <validation-observer v-if="stage === 'request_mfa'" ref="mfaForm" #default="{ invalid }">
            <b-form class="auth-login-form mt-2" @submit.prevent="login(false)">
              <b-card no-body v-for="mfa in mfaMethods" :key="mfa.id">
                <b-card-body class="d-flex align-items-center">
                  <b-avatar :variant="`light-primary`" size="45">
                    <font-awesome-icon size="2x" :icon="iconOfMfaType(mfa.type)" />
                  </b-avatar>

                  <div class="truncate ml-1">
                    <h4 class="mb-25 font-weight-bolder">
                      {{ mfa.name || title(mfa.type) }}
                    </h4>
                    <span>{{ mfa.display }}</span>
                  </div>

                  <a class="ml-auto" href="javascript:" @click="requestMfa(mfa)">
                    <b-avatar :variant="`light-primary`" size="35">
                      <font-awesome-icon size="1x" icon="fa-solid fa-arrow-right-from-bracket" />
                    </b-avatar>
                  </a>
                </b-card-body>
              </b-card>
            </b-form>
          </validation-observer>

          <!-- form -->
          <validation-observer v-if="stage === 'submit_mfa'" ref="mfaForm" #default="{ invalid }">
            <b-form class="auth-login-form mt-2" @submit.prevent="login">
              <!-- email -->
              <b-form-group label="Verification Code" label-for="verification-code">
                <validation-provider #default="{ errors }" name="Verification Code" vid="code" rules="required|string">
                  <b-form-input
                    id="verification-code"
                    v-model="mfaCode"
                    :state="errors.length > 0 ? false : null"
                    name="verification-code"
                    placeholder="975871"
                    tabindex="1"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- submit buttons -->
              <b-button type="submit" variant="primary" block :disabled="!mfaCode" tabindex="3"> Sign in </b-button>
            </b-form>
          </validation-observer>
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import VuexyLogo from '@core/layouts/components/Logo.vue';
import {
  BAlert,
  BAvatar,
  BButton,
  BCard,
  BCardBody,
  BCardText,
  BCardTitle,
  BCol,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BImg,
  BInputGroup,
  BInputGroupAppend,
  BLink,
  BRow,
  VBTooltip,
} from 'bootstrap-vue';
import useJwt from '@/auth/jwt/useJwt';
import { email, required } from '@validations';
import { togglePasswordVisibility } from '@core/mixins/ui/forms';
import store from '@/store/index';
import { getHomeRouteForLoggedInUser } from '@/auth/utils';
import { title } from '@core/utils/filter';

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import ability from '@/libs/acl/ability';

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BCard,
    BCardBody,
    BAvatar,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BAlert,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: '',
      userEmail: '',
      sideImg: require('@/assets/images/pages/login/left.svg'),
      mfa: {},
      mfaCode: undefined,

      tagline: 'Please sign-in to your account.',

      stage: 'login', // login request_mfa submit_mfa

      mfaMethods: [],

      // validation rules
      required,
      email,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon';
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login/left.svg');
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
  methods: {
    iconOfMfaType(type) {
      switch (type) {
        case 'time_secret':
          return 'fa-solid fa-clock';
        case 'email':
          return 'fa-solid fa-envelope';
        case 'sms':
          return 'fa-solid fa-comment-sms';
        case 'discord':
          return 'fa-brands fa-discord';
        case 'override':
          return 'fa-solid fa-lock';
        default:
          return 'fa-solid fa-user-secret';
      }
    },
    async loadMfaMethods() {
      useJwt
        .retrieveMfa(this.userEmail)
        .then((response) => {
          if (Array.isArray(response.data)) {
            this.mfaMethods = response.data;

            if (this.mfaMethods.length === 1) {
              // if we only have 1 type of mfa enabled, skip the select page and send straight away
              this.requestMfa(this.mfaMethods[0]);
            }
          }
        })
        .catch((error) => {
          this.processing = false;
          this.displayError(error, 'Failed to load mfa methods');
        });
    },
    async requestMfa(mfa) {
      const { id: methodId } = mfa;

      useJwt
        .requestMfaCode({ method: methodId })
        .then((response) => {
          if (response.data && response.data.successful) {
            this.mfa = mfa;
            this.tagline = 'Please enter the verification that was generated';
            this.stage = 'submit_mfa';
          }
        })
        .catch((error) => {
          this.processing = false;
          this.displayError(error);
        });
    },

    displayError(error, title = 'Failed to authenticate') {
      if (error.response) {
        const { data } = error.response;

        if (data.message) {
          return this.$toast({
            component: ToastificationContent,
            props: {
              title,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: data.message,
            },
          });
        }
      }

      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon: 'AlertTriangleIcon',
          variant: 'danger',
          text: 'An unknown error has occurred.',
        },
      });
    },

    login(validate = true) {
      const exec = () =>
        useJwt
          .login({
            email: this.userEmail,
            password: this.password,
            code: this.mfaCode,
          })
          .then(async (response) => {
            const data = response.data;

            const { user, tokens, permissions } = data;
            useJwt.setToken(tokens.access.token);
            useJwt.setRefreshToken(tokens.refresh.token);

            if (user.name && user.email && window.Tawk_API) {
              window.Tawk_API.visitor = {
                name: user.name,
                email: user.email,
              };
            }

            localStorage.setItem('userData', JSON.stringify(user));

            ability.setPermissions(permissions, true);

            // ? This is just for demo purpose. Don't think CASL is role based in this case, we used role in if condition just for ease
            this.$router.replace(getHomeRouteForLoggedInUser(user.role)).then(() => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `Welcome ${user.name}`,
                  icon: 'CoffeeIcon',
                  variant: 'success',
                  text: `You have successfully logged in.`,
                },
              });
            });
          })
          .catch((error) => {
            this.processing = false;

            if (error.response) {
              const { data } = error.response;

              if (data.message === 'Must supply mfa code') {
                this.stage = 'request_mfa';
                this.tagline = 'Please select a method to verify your login';
                this.loadMfaMethods();

                return;
              }
            }

            this.displayError(error);
          });

      if (validate === true) {
        this.$refs.loginForm.validate().then((success) => {
          if (success) {
            exec();
          }
        });
      } else {
        exec();
      }
    },
  },
  setup(props) {
    return { title };
  },
};
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/pages/page-auth.scss';

.card-body {
  background-color: rgb(10, 0, 45) !important;
  border-radius: 7px;
}
</style>
